<template>
<div class="content">
    <div class="title-actions">
      <h5 class="title">{{ $t('bets') }} VS - {{ title }}</h5>
      <div class="btns">
        <b-form-input v-model="filter" :placeholder="$t('search')" autofocus />
        <b-button :to="{ name: 'BetsCreate', params: { lang: lang, console: console } }" variant="info">
          <i class="fa fa-plus px-1"></i>
        </b-button>
        <b-button @click="$router.go(-1)">
          <i class="fa fa-arrow-left"></i>
        </b-button>
      </div>
    </div>
    <hr>
    <b-form class="row" @submit.prevent="save()">
      <div class="form-group col-6 col-md-3">
        <label for="console">{{ $t('console') }}</label>
        <multiselect v-validate="'required'" :state="validateState('console')" name="console" id="console" v-model="bet.console" :options="consoles" deselectLabel="" :placeholder="$t('selectOption')" :searchable="false" selectLabel="" selectedLabel=""></multiselect>
        <span class="error-inputs">{{ errors.first('console') }}</span>
      </div>
      <div class="form-group col-6 col-md-3">
        <label for="name">{{ $t('name') }}</label>
        <b-form-input name="name" v-model="bet.name" v-validate="'required|max:75'" :state="validateState('name')"></b-form-input>
        <span class="error-inputs">{{ errors.first('name') }}</span>
      </div>
      <div class="form-group col-6 col-md-3">
        <label for="bet">{{ $t('matchBetting') }}</label>
        <b-form-input type="number" name="bet" v-model="bet.bet" v-validate="'required|numeric'" :state="validateState('bet')"></b-form-input>
        <span class="error-inputs">{{ errors.first('bet') }}</span>
      </div>
      <div class="form-group col-6 col-md-3">
        <label for="gain">{{ $t('matchWin') }}</label>
        <b-form-input type="number" name="gain" v-model="bet.gain" v-validate="'required|numeric'" :state="validateState('gain')"></b-form-input>
        <span class="error-inputs">{{ errors.first('gain') }}</span>
      </div>
      <div class="col-12">
        <b-button type="submit" variant="primary">{{ btnText }}</b-button>
        <b-button @click="$router.go(-1)" class="ml-2">{{ $t('cancel') }}</b-button>
      </div>
    </b-form>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      bets: [],
      bet: {},
      filter: '',
      betId: null,
      title: '',
      consoles: window.consoles,
      btnText: '',
      columns: [
        { key: 'name', label: this.$t('name'), sortable: true },
        { key: 'created_at', label: this.$t('createdAgo'), sortable: true },
        { key: 'actions', label: '' }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'lang',
      'console',
      'user'
    ])
  },
  mounted() {
    const betId = this.$route.params.betId
    if (betId) {
      this.betId = betId;
      this.btnText = this.$t('toUpdate')
      this.title = this.$t('edit')
      this.fetchData()
    } else {
      this.btnText = this.$t('save')
      this.title = this.$t('create')
    }
    document.title = `${this.$t('bets')} VS`
  },
  methods: {
    fetchData () {
      const path = `auth/${this.lang}/${this.console}/super/bets/${this.betId}/edit`
      this.$axios.get(path).then(response => {
        this.bet = response.data.data
      })
    },
    save () {
      this.$validator.validate().then(result => {
        if (result) {
          if (this.betId) {
            const path = `auth/${this.lang}/${this.console}/super/bets/${this.betId}/update`
            this.$axios.put(path, this.bet).then((response) => {
              this.$toastr.success(response.data.message, window.TITLE_SUCCESS)
              this.$router.push({ name: 'Bets', params: { lang: this.lang, console: this.console } })
            })
          } else {
            const path = `auth/${this.lang}/${this.console}/super/bets/store`
            this.$axios.post(path, this.bet).then((response) => {
              this.$toastr.success(response.data.message, window.TITLE_SUCCESS)
              this.$router.push({ name: 'Bets', params: { lang: this.lang, console: this.console } })
            })
          }
        } else {
          this.showValidationsErrors(this.errors.items);
        }
      })
    }
  }
}
</script>
